<template>
  <div class="common-table">
    <el-table
      :data="tableData"
      border
      stripe
      show-summary
      :summary-method="getSum"
      header-row-class-name="header-row"
      header-cell-class-name="header-cell"
      row-class-name="normal-row"
      cell-class-name="normal-cell"
    >
      <el-table-column align="center" prop="cangku_name" label="回库仓库" min-width="10%"> </el-table-column>
      <el-table-column align="center" prop="cailiao_name" label="材料名称" min-width="10%"> </el-table-column>
      <el-table-column align="center" prop="cailiaoguige_name" label="材料规格" min-width="10%"> </el-table-column>
      <el-table-column align="center" prop="amount" label="回库数量" min-width="10%"> </el-table-column>
      <el-table-column align="center" prop="date" label="回库时间" min-width="10%"> </el-table-column>
      <el-table-column align="center" prop="remark" label="回库备注" min-width="20%"> </el-table-column>
      <el-table-column align="center" prop="added" label="添加" min-width="15%"> </el-table-column>
      <el-table-column align="center" label="管理" min-width="15%">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)" v-if="userType.wuzihuikudiaopei_edit">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-if="userType.wuzihuikudiaopei_del">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    tableData: Array,
    config: Object,
    userType: Object
  },
  methods: {
    //编辑
    handleEdit(row) {
      this.$emit('edit', row)
    },

    //删除
    handleDelete(row) {
      this.$emit('del', row)
    },

    //设置特殊行（如已结单）的状态
    // tableRowClassName(row) {
    //   console.log('row......:', row)
    //   let rowIndex = row.rowIndex
    //   console.log('rowindex:', rowIndex)
    //   if (rowIndex === 0) {
    //     return 'warning-row'
    //   } else if (rowIndex === 1) {
    //     return 'success-row'
    //   }
    //   return ''
    // },
    //合计
    getSum(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        // column.property === 'bmoney'  bmoney 是你要总计table中的那一列的 prop值
        if (column.property === 'amount') {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          // 如果要格式化 或者处理数据  比方说加千位符,默认保留两位小数等等  直接处理sums[index]就可以
          // sums[index] += sums[index];sums[index];
        } else {
          sums[index] = '--'
        }
      })
      return sums
    }
  }
}
</script>
